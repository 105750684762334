<template>
  <MatchMedia query="(max-width: 840px)" v-slot="{ matches }">
    <div v-if="matches" class="mobileBox">
      <div class="filter">
        <div class="total">
          <div class="tip">
            Total Documents
            <span class="num">
              {{ total }}
            </span>
          </div>
        </div>
        <div class="item">
          <div class="tip"></div>
          <router-link to="/documents/create">
            <Button type="primary" icon="md-add">Add Document</Button>
          </router-link>
        </div>
      </div>
      <div class="filter">
        <div class="search-box" style="width:100%;margin:0">
          <div class="input-box">
            <input
              v-on:keyup.enter="handleSearch"
              v-model="filter.search"
              type="text"
              placeholder="Search for a document..."
            />
          </div>
          <div class="btn" @click="handleSearch">Search</div>
        </div>
      </div>
      <ul class="lists" v-if="list.length">
        <li v-for="(item, index) in list" :key="index">
          <h3>
            <span>{{ item.name }}</span>
          </h3>
          <div class="content">
            <div class="con">
              <p class="tit">DOCUMENT TYPE</p>
              <p class="name">{{ (item.extra && item.extra.type) || "--" }}</p>
            </div>
          </div>
          <div class="footer">
            <div class="doit" @click="edit(item)">
              <Icon type="md-create" />
              Edit Document
            </div>
            <div class="doit" @click="removeDocument(item)">
              <Icon type="ios-remove-circle" />
              Remove Document
            </div>
          </div>
        </li>
      </ul>
      <p style="padding:100px 0;text-align:center" v-else>No Data</p>
      <p class="loadingLists" v-if="loading"><spin></spin></p>
      <div class="page">
        <Page
          :page-size="limit"
          :total="total"
          show-total
          @on-change="pageChange"
        />
      </div>
    </div>
    <div class="box" v-else>
      <div class="filter">
        <div class="total">
          <div class="tip">
            Total Documents
          </div>
          <div class="num">
            {{ total }}
          </div>
        </div>
        <div class="search-box">
          <div class="input-box">
            <input
              v-on:keyup.enter="handleSearch"
              v-model="filter.search"
              type="text"
              placeholder="Search for a document..."
            />
          </div>
          <div class="btn" @click="handleSearch">Search</div>
        </div>

        <div class="item">
          <div class="tip"></div>
          <router-link to="/documents/create">
            <Button type="primary" icon="md-add">Add Document</Button>
          </router-link>
        </div>
      </div>

      <div style="overflow-y:auto">
        <Table
          :loading="loading"
          :columns="columns"
          :data="list"
          style="min-width:500px;"
        >
          <template slot-scope="{ row: item }" slot="action">
            <Button size="small" @click="edit(item)">Edit</Button>
            <Divider type="vertical" />
            <Button size="small" @click="removeDocument(item)" type="error"
              >Delete</Button
            >
          </template>
        </Table>

        <div class="page" style="min-width:500px;">
          <Page
            :page-size="limit"
            :page-size-opts="[5, 10, 15, 20]"
            :total="total"
            show-sizer
            show-elevator
            show-total
            @on-change="pageChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </div>
    </div>
  </MatchMedia>
</template>

<script>
import api from "@/api";
import { MatchMedia } from "vue-component-media-queries";
const { _queryDocumentList, _removeDocument } = api;

export default {
  name: "documents",

  created() {
    this.queryDocumentList();
  },
  components: {
    MatchMedia
  },
  data() {
    return {
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,

      filter: {
        search: ""
      },

      columns: [
        {
          title: "DOCUMENT NAME",
          key: "name"
        },
        {
          title: "DOCUMENT TYPE",
          render: (h, params) => {
            return h(
              "span",
              (this.list[params.index].extra &&
                this.list[params.index].extra.type) ||
                "--"
            );
          }
        },
        {
          title: "Action",
          key: "action",
          width: 200,
          align: "center",
          slot: "action"
        }
      ]
    };
  },

  methods: {
    edit(item) {
      this.$router.push(`/documents/${item.id}/edit`);
    },

    handleSearch() {
      this.page = 1;
      this.queryDocumentList();
    },

    queryDocumentList() {
      this.loading = true;

      _queryDocumentList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    pageChange(page) {
      this.page = page;
      this.queryDocumentList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryDocumentList();
    },

    removeDocument(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Delete",
        content: "Are you sure to delete this item?",
        onOk: () => {
          _removeDocument({
            id: item.id
          })
            .then(() => {
              this.$Message.success("success");
              this.queryDocumentList(); // refresh
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.$Modal.remove();
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.filter {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  .total {
    color: #333;
    .tip {
    }
    .num {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .search-box {
    flex: 1;
    height: 40px;
    display: flex;
    margin: 0 20px;
    .input-box {
      position: relative;
      flex: 1;
      height: 100%;
      background: #fff;
      line-height: initial;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #dfdfdf;
      border-right: none;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      input {
        width: 100%;
        height: 100%;
        outline: none;
        background: transparent;
        border: none;
        color: #444;
        caret-color: #888;

        &::-webkit-input-placeholder {
          color: #bdcada;
        }
        &:-moz-placeholder {
          color: #bdcada;
        }
        &::-moz-placeholder {
          color: #bdcada;
        }
        &:-ms-input-placeholder {
          color: #bdcada;
        }
      }
    }
    .btn {
      cursor: pointer;
      background: #fff;
      color: @primary-color;
      border: 1px solid @primary-color;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-self: center;
      line-height: initial;
      height: 100%;
    }
  }
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="less" scoped>
.mobileBox {
  .loadingLists {
    width: 22px;
    margin: 100px auto;
  }
  .lists {
    list-style: none;
    li {
      margin-bottom: 10px;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      box-shadow: 0 0 4px #eee;
      h3 {
        font-size: 14px;
        padding: 6px 10px;
        border-bottom: 1px solid #f2f2f2;
        .fr {
          float: right;
        }
      }
      .content {
        display: flex;
        padding: 10px 10px;
        .con {
          flex: 0 0 50%;
          font-size: 12px;
          .tit {
            color: #888;
            padding: 4px 0;
          }
        }
      }
      .footer {
        display: flex;
        font-size: 12px;
        border-top: 1px solid #f2f2f2;
        .doit {
          flex: 0 0 50%;
          text-align: center;
          padding: 6px 0;
          &:first-child {
            color: #ff6600;
          }
          &:last-child {
            color: #ca1f01;
            border-left: 1px solid #f2f2f2;
          }
        }
        .not {
          color: #ccc !important;
        }
      }
    }
  }
}
.page {
  text-align: center;
}
</style>
